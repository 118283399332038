<template>
  <div class="membership-list">
    <error :errors="membershipError" />
    <b-overlay :show="formBusy" rounded="sm">
      <portlet fluidHeight>
        <template v-slot:body>
          <div class="d-flex align-items-center justify-content-between">
            <b-pagination
              class="mb-0 mr-2"
              v-model="membershipListMeta.current_page"
              :total-rows="membershipListMeta.total"
              :per-page="membershipListMeta.per_page"
              aria-controls="membership"
              @change="onChangePage"
            />
            <div class="kt-searchbar d-none d-sm-flex w-25">
              <div class="kt-input-icon kt-input-icon--left">
                <b-input @blur="refreshList" v-model="title" type="search" class="form-control" placeholder="تۈر نامىنى كىرگۈزۈپ ئىزدەڭ" />
                <span class="kt-input-icon__icon kt-input-icon__icon--left">
                    <span><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                          <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero"></path>
                        </g>
                      </svg></span>
                  </span>
              </div>
            </div>
            <div class="actions">
              <button @click="refreshList" v-b-tooltip.hover  title="يېڭىلاش" class="btn btn-clean btn-sm btn-icon btn-icon-md">
                <i class="flaticon2-refresh-arrow"></i>
              </button>
              <b-button :to="{name: 'membership.create'}" class="btn btn-label-success btn-sm border-0">
                يېڭىدىن قوشۇش
              </b-button>
            </div>
          </div>
        </template>
      </portlet>
      <b-row>
        <b-col v-for="membership in membershipList" md="3" :key="membership.id" sm="6">
          <portlet title=" " fluidHeight head-class="kt-portlet__head--noborder">
            <template v-slot:toolbar>
              <b-form-checkbox v-b-tooltip title="ھالەت تەڭشىكى" :key="`${membership.id}_show`" switch @change="onSwitchShow(membership.id)" v-model="membership.is_show"/>
              <b-dropdown v-b-tooltip title="مەشغۇلات" toggle-class="text-decoration-none border-0 btn btn-clean btn-sm btn-icon btn-icon-md" no-caret>
                <template v-slot:button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item @click="deleteItem(membership.id)">
                  <i class="fa fa-trash"></i> ئۆچۈرۈش
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-slot:body>
              <div class="kt-widget kt-widget--user-profile-2">
                <div class="kt-widget__head">
                  <div class="kt-widget__info">
                    <a class="kt-widget__username">
                      {{ membership.title }}
                    </a>
                  </div>
                </div>
                <div class="kt-widget__body">
                  <div class="kt-widget__section">
                    {{ membership.description }}
                  </div>
                  <div class="kt-widget__item">
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">ئۇمۇمى سېتىلغىنى: </span>
                      <span class="kt-widget__data" >{{ membership.orders_count }} قېتىم</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">باھاسى: </span>
                      <span class="kt-widget__data" >{{ membership.price }} يۈەن</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">ئۈنۈملۈك مۇددىتى: </span>
                      <span class="kt-widget__data" >{{ membership.duration }} كۈن</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">ئېتبار نىسبىتى: </span>
                      <span class="kt-widget__data" style="direction: ltr">{{ membership.discount }} %</span>
                    </div>
                  </div>
                </div>
                <div class="kt-widget__footer">
                  <b-button :to="{name: 'membership.edit', params: {id: membership.id}}" type="button" variant="primary" class="btn btn-lg btn-upper">تەھرىرلەش</b-button>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <portlet v-if="membershipList.length < 1" class="kt-callout">
        <template v-slot:body>
          <div class="kt-callout__body">
            <div class="kt-callout__content">
              <h3 class="kt-callout__title">ئۇچۇر تېپىلمىدى</h3>
              <p class="kt-callout__desc">
                مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، يېڭىدىن ئۇچۇر قوشۇڭ
              </p>
            </div>
            <div class="kt-callout__action">
              <b-button :to="{name: 'membership.create'}" class="btn-custom btn-bold btn-upper btn-font-sm  btn-success">يېڭىدىن قوشۇش</b-button>
            </div>
          </div>
        </template>
      </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import Error from "../../../partials/widgets/Error";
  import Portlet from "../../../partials/content/Portlet";
  import tableList from "@/views/components/tableList"
  import { mapGetters } from "vuex";
  import membershipMixin from "../../../../mixins/membershipMixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  export default {
    name: "index",
    mixins: [ membershipMixin, formBusyMixin ],
    components: { Error, tableList, Portlet },
    computed: {
      ...mapGetters(["membershipError", "membershipList", "membershipListMeta"])
    },
    created() {
      this.getMembershipList()
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "VIP ئەزالىق تۈرى باشقۇرۇش", route: "list" },
        { title: "بارلىق VIP تۈرلىرى" }
      ]);
    },
    data(){
      return {
        title: '',
        formBusy: false,
      };
    },
    methods: {
      onChangePage(page){
        this.getMembershipList(page, this.title);
      },
      deleteItem(id) {
        this.$bvModal.msgBoxConfirm('مەزكۇر ئەزالىق تۈرىنى ئ‍ۆچۈرسىڭىز ئەسلىگە قايتۇرغىلى بولمايدۇ، ئۆچۈرۈشنى جەزىملەشتۈرەمسىز ؟', {
          title: 'راستىنلا ئۆچۈرەمسىز ؟',
          okVariant: 'danger',
          okTitle: 'جەزىملەشتۈرىمەن',
          cancelTitle: 'ياق',
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if ( ! value ) return;
          this.deleteMembership(id)
            .then(response=>{
              this.getMembershipList();
            })
        })
        .catch(err => {
          // An error occurred
        })
      },
      onSwitchShow(e){
        this.switchMembershipState(e, {column: 'is_show'});
      },
      refreshList(){
        this.getMembershipList(1, this.title);
      },
    }
  }
</script>

<style>
  .kt-widget__head .kt-widget__info {
    overflow: hidden;
  }

  .kt-widget__section {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    height: 74px;
  }

  .kt-widget__data {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .kt-widget__username {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: justify;
    display: block;
    width: 100%;
  }
</style>
