<template>
  <div class="table-list-container">
    <b-overlay :show="tableBusy" rounded="sm">
    <portlet :title="portletTitle">
      <template v-slot:toolbar>
        <slot name="toolbar"></slot>
      </template>
      <template v-slot:body>
        <b-table :show-empty="true" :id="tableId" :fields="tableFields" :items="tableItems">
          <template v-slot:empty="scope">
            <p class="text-center"> ھازىرچە مۇناسىۋەتلىك ئۇچۇر يوق ئىكەن . </p>
          </template>
          <template v-slot:table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>سەل ساقلاڭ</strong>
            </div>
          </template>
          <template v-slot:cell(avatar)="row">
            <b-img height="40" :src="row.value" />
          </template>
          <template v-slot:cell(image)="row">
            <b-img-lazy height="40" :src="row.value" blank-src="/assets/media/misc/loading.svg" />
          </template>
          <template v-slot:cell(preview)="row">
            <b-img-lazy height="40" :src="row.value" blank-src="/assets/media/misc/loading.svg" />
          </template>
          <template v-slot:cell(cover_image_preview)="row">
            <b-img-lazy height="40" :src="row.value" blank-src="/assets/media/misc/loading.svg" />
          </template>
          <template v-slot:cell(actions)="row">
            <slot name="tableActions" v-bind:row="row.item" ></slot>
          </template>
        </b-table>
        <b-pagination
          v-if="paginateMeta.total"
          class="my-2"
          v-model="paginateMeta.current_page"
          :total-rows="paginateMeta.total"
          :per-page="paginateMeta.per_page"
          :aria-controls="tableId"
          @input="changePage"
        />
      </template>
    </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "../../partials/content/Portlet";
  export default {
    name: "index",
    components: { Portlet },
    props: {
      portletTitle: {
        type: String,
        required: true
      },
      tableId: {
        type: String,
        default: "listTable"
      },
      tableBusy: Boolean,
      tableFields: Array,
      tableItems: Array,
      tableActions: String,
      paginateMeta: Object,
      changePage: {
        type: Function,
        default: (item) => item
      }
    },
    data(){
      return {};
    }
  }
</script>

<style scoped>

</style>
